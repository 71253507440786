import { inject } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';

export function useApi() {
  const toast = useToast();
  const loader = inject('loader');
  const defaultOptions = {
    showLoader: true,
    responseType: 'json'
  };

  const makeRequest = async (
    method,
    url,
    data = null,
    params = {},
    options = {}
  ) => {
    options = {...defaultOptions, ...options};

    if (options.showLoader) {
      loader.increment();
    }

    url = window.$udabApiUrl + url;
    try {
      const response = await axios({
        method,
        url,
        data,
        params,
        responseType: options.responseType
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      if (options.showLoader) {
        loader.decrement();
      }
    }
  };

  const get = (url, params, options = {}) =>
    makeRequest('get', url, null, params, options);

  const post = (url, data, options = {}) =>
    makeRequest('post', url, data, {}, options);

  const put = (url, data, options = {}) =>
    makeRequest('put', url, data, {}, options);

  const patch = (url, data, options = {}) =>
    makeRequest('patch', url, data, {}, options);

  const deleteRequest = (url, data, params, options = {}) =>
    makeRequest('delete', url, data, params, options);

  const postFormData = async (url, data, params = {}, options = {}) => {
    options = { ...defaultOptions, ...options };

    if (options.showLoader) {
      loader.increment();
    }

    url = window.$udabApiUrl + url;

    try {
      const response = await axios.post(url, data, {
        params,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: options.responseType
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      if (options.showLoader) {
        loader.decrement();
      }
    }
  };

  return {
    get,
    post,
    put,
    patch,
    deleteRequest,
    postFormData
  };
}
