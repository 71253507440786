<template>
  <div class="card w-100">
    <div class="card-body">
      <button type="button" class="btn btn-outline-secondary mb-3" @click="goBack">
        <i class="fa fa-arrow-left me-2"></i>
        Back to Roles
      </button>
      <h4 class="fw-semibold mb-4">{{ role ? 'Edit Role' : 'Create Role' }}</h4>
      <form @submit.prevent="onSubmit">
        <div class="mb-3">
          <label for="role-name" class="form-label">Name</label>
          <input id="role-name" type="text" class="form-control" v-model="form.name" required/>
        </div>
        <div class="row">
          <div v-for="(permissions, groupName) in PERMISSION_GROUPS" :key="groupName" class="col-md-3 mt-4 mb-3">
            <h5>{{ groupName }}</h5>
            <div v-for="permission in permissions" :key="permission" class="form-check">
              <input class="form-check-input" type="checkbox" :id="permission" :value="permission"
                     v-model="form.permissions"/>
              <label class="form-check-label" :for="permission">
                {{ permission }}
              </label>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <button type="submit" class="btn btn-primary">
            {{ role ? 'Save' : 'Create' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useApi } from '@/helpers/udab-api';
import { PERMISSION_GROUPS } from '@/constants/permissions';
import swal from 'sweetalert';
import { useToast } from 'vue-toastification';

const router = useRouter();
const route = useRoute();
const api = useApi();
const toast = useToast();

const role = ref(null);
const form = ref({
  name: '',
  permissions: [],
});

function goBack() {
  router.push({name: 'roles'});
}

async function fetchRole() {
  if (route.params.id) {
    role.value = await api.get(`/roles/${route.params.id}`);
    form.value.name = role.value.name;
    form.value.permissions = role.value.permissions;
  }
}

async function onSubmit() {
  const data = {
    name: form.value.name,
    permissions: form.value.permissions,
  };
  if (role.value) {
    await api.patch(`/roles/${role.value.id}`, data);
    toast.success('Role updated');
  } else {
    await api.post('/roles', data);
    toast.success('Role created');
  }
  router.push({name: 'roles'});
}

const isEditMode = computed(() => route.name === 'EditRole');

onMounted(() => {
  if (isEditMode.value) {
    fetchRole();
  }
});
</script>
