import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import axios from 'axios';

const app = createApp(App);
const envs = {
  'stage.abstraktintelligence.com': 'https://api-stage.abstraktintelligence.com',
  'abstraktintelligence.com': 'https://api.abstraktintelligence.com'
};

const hostname = window.location.hostname;
const regex = /^udab-client-(\d+)\.udab\.abstraktmg\.com$/;

if (envs[hostname]) {
  window.$udabApiUrl = envs[hostname];
} else if (regex.test(hostname)) {
  const match = hostname.match(regex);

  window.$udabApiUrl = `https://udab-client-${match[1]}-api.udab.abstraktmg.com`;
} else if (process.env.VUE_APP_UDAB_API_URL) {
  window.$udabApiUrl = process.env.VUE_APP_UDAB_API_URL;
} else {
  alert('Missing uDab API');
}

app
  .use(router)
  .use(Toast, {
    draggable: false
  })
  .mount('#app');
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

