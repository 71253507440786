import { createRouter, createWebHistory } from 'vue-router';
import UnauthenticatedLayout from '@/layouts/UnauthenticatedLayout.vue';
import RoleForm from '@/pages/user/RoleForm.vue';

const routes = [
  {
    path: '/auth',
    component: UnauthenticatedLayout,
    children: [
      {path: 'login', name: 'login', component: () => import('@/pages/login/LoginPage')},
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/pages/login/ForgotPasswordPage')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthenticatedLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {path: '', name: 'dashboard', component: () => import('@/pages/dashboard/DashboardPage')},
      {
        path: 'accounts',
        name: 'accounts',
        component: () => import('@/pages/directory/Accounts.vue')
      },
      {
        path: 'accounts/:id',
        name: 'account',
        component: () => import('@/pages/directory/Account.vue')
      },
      {
        path: 'extension',
        name: 'extension',
        component: () => import('@/pages/extension/ExtensionPage.vue')
      },
      {
        path: 'heyreach',
        name: 'heyreach',
        component: () => import('@/pages/heyreach/HeyreachPage.vue')
      },
      {
        path: 'smartlead',
        name: 'smartlead',
        component: () => import('@/pages/smartlead/SmartleadPage.vue')
      },
      {
        path: 'documentation',
        name: 'documentation',
        component: () => import('@/pages/documentation/DocumentationPage')
      },
      {path: 'settings', name: 'settings', component: () => import('@/pages/settings/SettingsPage')},
      {
        path: 'users',
        name: 'users',
        component: () => import('@/pages/user/Users.vue')
      },
      {
        path: '/roles',
        name: 'roles',
        component: () => import('@/pages/user/Roles.vue'),
      },
      {
        path: '/roles/create',
        name: 'CreateRole',
        component: RoleForm,
      },
      {
        path: '/roles/:id',
        name: 'EditRole',
        component: RoleForm,
        props: true,
      },
      {
        path: '/users/create',
        name: 'CreateUser',
        component: () => import('@/pages/user/UserForm.vue'),
      },
      {
        path: '/users/:id',
        name: 'EditUser',
        component: () => import('@/pages/user/UserForm.vue'),
        props: true,
      },
      {
        path: '/dnc',
        name: 'dnc',
        component: () => import('@/pages/dnc/Dnc.vue')
      },
    ]
  },
  // 404 Handler
  {
    path: '/:pathMatch(.*)*',
    component: UnauthenticatedLayout,
    children: [{path: '', name: '404', component: () => import('@/NotFound')}]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem('authToken');
  const isAuthenticated =
    authToken && Math.floor(Date.now() / 1000) < authToken;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/auth/login');
  } else if (!to.meta.requiresAuth && isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
