export const VIEW_CLIENTS = 'View Clients';
export const CREATE_CLIENTS = 'Create Clients';
export const UPDATE_CLIENTS = 'Update Clients';
export const DELETE_CLIENTS = 'Delete Clients';
export const EXPORT_CLIENTS = 'Export Clients';
export const DIRECTORY_PERMISSIONS = [VIEW_CLIENTS, CREATE_CLIENTS, UPDATE_CLIENTS, DELETE_CLIENTS, EXPORT_CLIENTS];

export const VIEW_SEQUENCER = 'View Sequencer';
export const EDIT_SEQUENCES = 'Edit Sequences';
export const SEQUENCER_PERMISSIONS = [VIEW_SEQUENCER, EDIT_SEQUENCES];

export const VIEW_DNC = 'View DNC';
export const CREATE_DNC_RECORDS = 'Create DNC Records';
export const DELETE_DNC_RECORDS = 'Delete DNC Records';
export const UPLOAD_DNC_LISTS = 'Upload DNC LISTS';
export const DNC_PERMISSIONS = [VIEW_DNC, CREATE_DNC_RECORDS, DELETE_DNC_RECORDS, UPLOAD_DNC_LISTS];

export const VIEW_USERS = 'View Users';
export const CREATE_USERS = 'Create Users';
export const UPDATE_USERS = 'Update Users';
export const DELETE_USERS = 'Delete Users';
export const USER_PERMISSIONS = [VIEW_USERS, CREATE_USERS, UPDATE_USERS, DELETE_USERS];

export const MANAGE_HEYREACH_INSTANCES = 'Manage HeyReach Instances';
export const MANAGE_SMARTLEAD_INSTANCES = 'Manage SmartLead Instances';
export const VIEW_DOCUMENTATION = 'View Documentation';
export const CONFIGURE_PERMISSIONS = [MANAGE_HEYREACH_INSTANCES, MANAGE_SMARTLEAD_INSTANCES, VIEW_DOCUMENTATION];

export const MANAGE_SETTINGS = 'Manage Settings';
export const MANAGE_TAGS = 'Manage Tags';
export const SETTINGS_PERMISSIONS = [MANAGE_SETTINGS, MANAGE_TAGS];

export const PERMISSION_GROUPS = {
  'Directory': DIRECTORY_PERMISSIONS,
  'Sequencer': SEQUENCER_PERMISSIONS,
  'DNC': DNC_PERMISSIONS,
  'Users': USER_PERMISSIONS,
  'Configure': CONFIGURE_PERMISSIONS,
  'Settings': SETTINGS_PERMISSIONS,
};
